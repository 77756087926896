import { InsightType } from 'data/insightData';
import { BarChart2, CircleAlert, Lightbulb } from 'lucide-react';

interface InsightTypebadgeProps {
  insightType: InsightType;
}

const InsightTypeBadge: React.FC<InsightTypebadgeProps> = ({ insightType }) => {
  let color = '';
  let colorHex = '';
  let label = '';
  let icon = null;
  switch (insightType) {
    case InsightType.PRESCRIPTIVE:
      color = 'pink';
      colorHex = '#fce7f3';
      label = 'Prescriptive';
      icon = <Lightbulb size={14} className="mr-1" />;
      break;
    case InsightType.ANOMALIES:
      color = 'cyan';
      colorHex = '#cffafe';
      label = 'Anomalies';
      icon = <CircleAlert size={14} className="mr-1" />;
      break;
    case InsightType.TREND_DETECTION:
      color = 'purple';
      colorHex = '#f3e8ff';
      label = 'Trends';
      icon = <BarChart2 size={14} className="mr-1" />;
      break;
    default:
  }

  return (
    <div
      className={`bg-${color}-100 rounded-full w-fit flex flex-row items-center justify-center h-6 text-xs px-2`}
      style={{ backgroundColor: colorHex }}>
      {icon}
      {label}
    </div>
  );
};

export default InsightTypeBadge;
