import { useUser } from 'contexts/UserContext';
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { ArrowUp } from 'lucide-react';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { TableAccessContext } from 'contexts/TableAccessContext.tsx';
import LeniQAnswerComponent from '../LeniQAnswerComponent';
import LeniQQuestionComponent from '../LeniQQuestionComponent';
import LeniQQuestion from '../LeniQQuestion';
import LeniQCommentInput from '../LeniQCommentInput';
import { AxiosResponse } from 'axios';
import { GptRunModelsData } from 'src/types/Services/GPT/gpt';
import { GptApi } from 'services/api/gpt';
import { UserApi } from 'services/api/user';
import { getScreenCapture } from 'utils/LeniQ/getScreenCapture';
import { processGptResponse } from 'utils/LeniQ/processGptResponse';
import { Shield } from 'lucide-react';
import { PRODUCT_NAMES } from 'data/trackerProductNames';
import { getTableauUrl } from 'utils/getTableauUrl';
import { TableauDashboardContext } from 'contexts/TableauDashboardContext';

interface LeniQChatProps {
  handleQuestionClick: (question: string) => void;
}

interface LeniQQuestionAnswerProps {
  question: string;
  questionIndex: number;
  questionId: number;
  isProcessing: boolean;
  renderCompleted: boolean;
  hasError: boolean;
  answer: any;
}

const DEMO_QUESTIONS = [
  'Provide me an executive summary of my portfolio',
  'How is the marketing performance this quarter?',
  'Provide an update on Troy Boston occupancy',
  'Identify revenue improvement opportunities',
];

const QUESTIONS = [
  'What is the overall lead to application conversion rate for this month?',
  'What is the total number of open work orders?',
  'Tell me main reason tenants are moving out?',
];

const formSchema = z.object({
  question: z.string().min(1, {
    message: 'A question cannot be empty',
  }),
});

const LeniQChat: React.FC<LeniQChatProps> = () => {
  const { user: currentUser } = useUser();
  const [questions, setQuestions] = useState<string[]>([]);
  const tableAccessContext = useContext(TableAccessContext);
  const [questionsAnswers, setQuestionsAnswers] = useState<LeniQQuestionAnswerProps[]>([]);
  const questionAnswerContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser?.email === 'dev.prod.soulrooms@gmail.com') {
      setQuestions(DEMO_QUESTIONS);
    } else {
      setQuestions(QUESTIONS);
    }
  }, [currentUser]);

  const getDashboardUrl = async () => {
    return getTableauDashboardUrl();
  };
  const {
    tableauDashboardState: { currentDashboard, dashboardTabIndex },
  } = useContext(TableauDashboardContext);

  const getTableauDashboardUrl = async () => {
    const res = await UserApi.getTableauJWT();
    const token = res.data;
    const userId = currentUser?.id ?? '';
    const organisationId = currentUser?.organisationId ?? '';
    const baseUrl = getTableauUrl(currentDashboard, dashboardTabIndex) ?? '';
    const urlParams = new URLSearchParams({ baseUrl, token, organisationId, userId }).toString();
    return `${window.location.origin}/ghost-tableau?${urlParams}`;
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      question: '',
    },
  });

  const isQuestionAboutExecutiveSummary = (question: string) => {
    return question.toLowerCase().includes('executive summary');
  };

  function processQuestion(question: string) {
    const questionId = new Date().getTime();
    setQuestionsAnswers(prevAnswers => {
      return [
        ...prevAnswers,
        {
          question,
          questionIndex: prevAnswers.length + 1,
          questionId,
          isProcessing: true,
          renderCompleted: false,
          hasError: false,
          answer: null,
        },
      ];
    });
  }

  // Add this useEffect to watch for new questions
  useEffect(() => {
    const lastQuestion = questionsAnswers[questionsAnswers.length - 1];
    if (lastQuestion?.isProcessing && !lastQuestion.answer) {
      askGptQuestion(lastQuestion.question, lastQuestion.questionId);
    }
  }, [questionsAnswers]);

  function setQuestionAnswer(question: string, questionId: number, answer: any, hasError: boolean) {
    setQuestionsAnswers(prevAnswers => {
      return prevAnswers.map(qa => {
        if (qa.questionId === questionId) {
          return { ...qa, answer, questionId: answer.questionId, isProcessing: false, hasError };
        }
        return qa;
      });
    });
  }

  function setQuestionAnswerRendered(questionId: number) {
    setQuestionsAnswers(prevAnswers => {
      return prevAnswers.map(qa => {
        if (qa.questionId === questionId) {
          return { ...qa, renderCompleted: true };
        }
        return qa;
      });
    });
  }

  const askGptQuestion = useCallback(
    async (question: string, questionId: number) => {
      if (!currentUser) return;
      setLoading(true);
      try {
        const showPrewrittenResponse = (summary: string) => {
          setTimeout(() => {
            setQuestionAnswer(
              question,
              questionId,
              {
                answer: [{ count: 1 }],
                questionId,
                summary,
                tableData: [],
              },
              false
            );
            setLoading(false);
          }, 4000); // Wait for 4 seconds to simulate loading
        };
        const isDashboardPageLoaded = document.location.pathname.includes('/dashboard/analytics');
        const doesQuestionIsAboutExecutiveSummary = isQuestionAboutExecutiveSummary(question);
        if (currentUser?.email === 'dev.prod.soulrooms@gmail.com' && isQuestionAboutExecutiveSummary(question)) {
          showPrewrittenResponse(`
            <strong>Troy Boston</strong> is situated in Boston and has a revenue of <strong>$11,296,258. After accounting for expenses,
            which total $4,828,626</strong>, the gross margin stands strong at <strong>$6,467,632</strong>. The building demonstrates a <strong>good
            occupancy rate of 98.89%</strong> when compared to the neighborhood occupancy.
            <br />
            <br />
            Meanwhile, <strong>Parkway Apartments</strong> is another building in Boston. Generating a <strong>revenue of $12,285,020</strong> and incurring <strong>expenses of $6,043,149</strong>,
            the apartments achieve a NOI of <strong>$6,241,871</strong>. The occupancy rate is slightly below that of Troy Boston,
            settling at <strong>98.70%</strong>.
            <br />
            <br />
            In Toronto, <strong>Bridge Condos</strong> has made its mark with the highest occupancy rate among
            the listed properties, a remarkable <strong>99.14%</strong>. Financially, the condos have garnered a revenue of
            $4,539,450, and after deducting expenses of <strong>$2,298,619, the NOI is ascertained at $2,240,831</strong>.
            <br />
            <br />
            Lastly, <strong>Liberty Towers</strong>, also located in Toronto, is pulling in a revenue of <strong>$6,821,198</strong>. The expenses for this
            property amount to <strong>$3,050,755</strong>, resulting in a net operating income of <strong>$3,770,443</strong>. The occupancy rate
            is competitive, noted at <strong>98.87%</strong>.
            <br />
            <br />
            In summary, while Troy Boston holds the lead in terms of NOI, Bridge
            Condos in Toronto stands out with the highest occupancy rate.
          `);
        } else if (
          currentUser?.email === 'dev.prod.soulrooms@gmail.com' &&
          question.toLowerCase().includes('how is the marketing performance this quarter')
        ) {
          showPrewrittenResponse(`
            When evaluating lead generation, <strong>Zumper tops the list with 647 leads</strong>,
            followed closely by <strong>Facebook with 631 leads</strong>. Our <strong>website generated the fewest leads, totaling 221</strong>.
            <br/>
            Nevertheless, when considering conversion rates, the website has <strong>11.95%</strong>, whereas Facebook's rate stands at <strong>4.25%</strong>.
            Zumper <strong>recorded a 0% conversion rate</strong>.
            <br />
            <br />
            Given the marketing investment for each channel, our website emerges as the top performer, even with its lower lead count.
          `);
        } else if (
          currentUser?.email === 'dev.prod.soulrooms@gmail.com' &&
          question.toLowerCase().includes('provide an update on troy boston occupancy')
        ) {
          showPrewrittenResponse(`
            Troy Boston currently holds a <strong>99.0%</strong> occupancy rate, with projections indicating a decline to <strong>94.2% in the next 90 days</strong>. This is a shortfall from our target of <strong>97.3%</strong>, resulting in a <strong>-3.10% difference</strong>.
            <br/>
            <br/>
            There is a <strong>decrease in lease renewals</strong>. In the last month, anticipated renewal rates have slipped by <strong>5.0% to 43.1%</strong>. Feedback from move-out reasons points to pricing as a central concern. A minor price reduction might enhance renewal rates, bringing us closer to our desired occupancy objectives.
          `);
        } else if (
          currentUser?.email === 'dev.prod.soulrooms@gmail.com' &&
          question.toLowerCase().includes('identify revenue improvement opportunities')
        ) {
          showPrewrittenResponse(`
            Currently, your advertised rent in Toronto is priced <strong>10% lower</strong> than the recent average.
            Impressively, your portfolio exhibits a <strong>23% renewal growth</strong>, coupled with a <strong>2.10% average</strong> conversion rate.
            By strategically raising the rents while sustaining your present renewal rate, there's a potential to augment your earnings by an extra <strong>$17,784</strong>.
            <br/>
            <br/>
            Consider increasing the rent by <strong>$25 for units</strong> that are presently available.
            Furthermore, you have an edge in the Annex neighborhood, where your vacancy rate is notably lower than the local market standard.
            Capitalizing on this position can bolster your annual revenues by an additional <strong>$3,000</strong>.
          `);
        } else if (
          currentUser?.email === 'dev.prod.soulrooms@gmail.com' &&
          question.toLowerCase().includes('what has the average % gain in lease been')
        ) {
          showPrewrittenResponse(`
            The average percentage gain in lease across T6 is approximately <strong>1.84%</strong>.
          `);
        } else if (
          currentUser?.email === 'dev.prod.soulrooms@gmail.com' &&
          question.toLowerCase().includes('what is my expected rent revenue')
        ) {
          showPrewrittenResponse(`
            Your expected rent revenue is <strong>$8,403,601.20</strong>.
          `);
        } else if (doesQuestionIsAboutExecutiveSummary && !isDashboardPageLoaded) {
          showPrewrittenResponse(`
            Please visit the dashboard page in order to inquire about an executive summary.
          `);
        } else {
          const userPayload = {
            ...currentUser,
            tables: tableAccessContext?.state?.data?.tables,
          };
          const screenCaptureBase64 =
            doesQuestionIsAboutExecutiveSummary && !isDashboardPageLoaded ? await getScreenCapture() : null;
          const urlToSummarize =
            doesQuestionIsAboutExecutiveSummary && isDashboardPageLoaded ? await getDashboardUrl() : null;
          const previousHistory = questionsAnswers
            .filter(qa => qa.renderCompleted && qa.questionId !== questionId)
            .map(qa => ({
              question: qa.question,
              questionIndex: qa.questionIndex,
              questionId: qa.questionId,
              answer: qa.answer,
            }))
            .sort((a, b) => a.questionIndex - b.questionIndex);
          const res: AxiosResponse<GptRunModelsData> = await GptApi.runModels(
            question,
            userPayload,
            screenCaptureBase64,
            urlToSummarize,
            false,
            previousHistory
          );
          const answerInfo = processGptResponse(res);

          setQuestionAnswer(question, questionId, answerInfo, !answerInfo || answerInfo.hasError);
        }
      } catch (error) {
        console.log(error);
        setQuestionAnswer(question, questionId, { questionId }, true);
      } finally {
        UserApi.usageTracking({ productName: PRODUCT_NAMES.LENIQ });
        setLoading(false);
      }
    },
    [currentUser, tableAccessContext?.state?.data?.tables, questionsAnswers]
  );

  useEffect(() => {
    if (questionAnswerContainerRef.current && questionAnswerContainerRef.current.parentElement) {
      questionAnswerContainerRef.current.parentElement.scrollTo({
        top: questionAnswerContainerRef.current.scrollHeight,
        behavior: 'smooth', // This enables smooth scrolling
      });
    }
  }, [questionsAnswers]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    onQuestionAsked(values.question);
  }

  function onQuestionAsked(question: string) {
    console.log('question', question);
    setLoading(true);
    processQuestion(question);
    setLoading(false);
    form.setValue('question', '');
  }

  const WhiteDecorativeOverlay = () => (
    <div
      className="absolute  h-12  z-20 "
      style={{
        bottom: '140px',
        background: 'linear-gradient(0deg, #ffffff, transparent)',
        width: 'calc(100% - 32px)',
      }}></div>
  );

  return (
    <div
      className="p-4 h-full flex flex-col justify-between overflow-scroll"
      style={{ flex: '0 0 calc(100% - 210px)' }}>
      <WhiteDecorativeOverlay />
      {questionsAnswers.length === 0 && (
        <div>
          <h2 className="display-md font-bold bg-gradient-to-r from-[#ACDFFD] via-[#3F5E75] to-[#101828] bg-clip-text text-transparent bg-[length:200%_200%] animate-scroll-gradient">
            Hi, {currentUser?.firstName}
          </h2>
          <h4 className="text-lg font-bold">Ask LeniQ about your portfolio</h4>
          <div className="flex flex-col gap-2 mt-5">
            {questions.map(question => (
              <LeniQQuestion key={question} question={question} onClick={() => onQuestionAsked(question)} />
            ))}
          </div>
        </div>
      )}

      <div ref={questionAnswerContainerRef} className="pb-8">
        {questionsAnswers.map((questionAnswer: LeniQQuestionAnswerProps) => {
          return (
            <>
              <LeniQQuestionComponent question={questionAnswer.question} />
              <LeniQAnswerComponent
                question={questionAnswer.question}
                answer={questionAnswer.answer}
                hasError={questionAnswer.hasError}
                onRenderedAnimationCompleted={() => {
                  setQuestionAnswerRendered(questionAnswer.questionId);
                }}
              />
            </>
          );
        })}
      </div>
      <div className="fixed bottom-3">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex items-center justify-between p-1 pr-3 m-0 border border-input  rounded-xl shadow-md max-w-[3500px] z-100 bg-white-50">
            <FormField
              control={form.control}
              name="question"
              render={({ field }) => (
                <FormItem className="flex-grow">
                  <FormControl>
                    <LeniQCommentInput
                      onSubmit={() => {
                        if (!loading) {
                          form.handleSubmit(onSubmit)();
                        }
                      }}
                      placeholder="Ask LeniQ a question..."
                      className="w-[275px]"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <button
              type="submit"
              className={`cursor-pointer p-[5px] bg-gray-400 rounded-full ${loading ? 'opacity-25' : ''}`}
              disabled={loading}>
              <ArrowUp height={15} width={15} className="text-white-50" />
            </button>
          </form>
        </Form>
        <div className="flex items-center justify-center gap-1 mb-4 mt-4">
          <Shield height={15} width={15} className="text-gray-400" />
          <span className="text-xs text-gray-400 mt-1">Privacy first integration with LLMs</span>
        </div>
      </div>
    </div>
  );
};

export default LeniQChat;
