import http from '../http';

/**
 * User Api Service
 */
export class UserApiService {
  urlPrefix = 'api/users';

  getCurrentUser() {
    return http.get(`${this.urlPrefix}/me`);
  }

  updateCurrentUser(data) {
    return http.put(`${this.urlPrefix}/me`, data);
  }

  updateSubuser(data) {
    return http.post(`${this.urlPrefix}/subuser/update`, data);
  }

  deleteSubUser(emailOfSubuserToDelete) {
    return http.post(`${this.urlPrefix}/subuser/delete`, { email: emailOfSubuserToDelete });
  }

  getUser(userId) {
    return http.get(`${this.urlPrefix}/${userId}`);
  }

  getUsersForOrganisation(organisationId) {
    return http.get(`${this.urlPrefix}/organisation/${organisationId}`);
  }

  getUsersManaged() {
    return http.get(`${this.urlPrefix}/users-managed`);
  }

  subscribe(data) {
    return http.post(`${this.urlPrefix}/subscribe`, data);
  }

  subscribeMoreUserToSlack(data) {
    return http.post(`${this.urlPrefix}/subscribe-to-slack`, data);
  }

  contactUs(data) {
    return http.post(`${this.urlPrefix}/contactUs`, data);
  }

  // deleted getAllUser()

  initiateCustomScreening(data) {
    return http.post(`${this.urlPrefix}/screening/custom-initiate`, data);
  }

  initiateRentifyScreening(data) {
    return http.post(`${this.urlPrefix}/screening/rentify-initiate`, data);
  }

  screeningFinalize(screenId, data) {
    return http.post(`${this.urlPrefix}/screening/finalize?screenId=${screenId}`, data);
  }

  getScreeningData(userId) {
    return http.get(`${this.urlPrefix}/${userId}/screening`);
  }

  createRoomieProfile(data) {
    return http.put(`${this.urlPrefix}/me/profile`, data);
  }

  getFeedback(data) {
    return http.get(`${this.urlPrefix}/feedback`, { params: data });
  }

  createFeedback(data) {
    return http.post(`${this.urlPrefix}/feedback`, data);
  }

  getNylasAuthUrl(data) {
    return http.get(`${this.urlPrefix}/nylas-get-auth-url`, { params: data });
  }

  getNylasToken(data) {
    return http.get(`${this.urlPrefix}/nylas-auth`, { params: data });
  }

  nylasRemoveAuth(data) {
    return http.get(`${this.urlPrefix}/nylas-remove-auth`, { params: data });
  }

  sendMessage(data) {
    return http.post(`${this.urlPrefix}/nylas-send-email`, data);
  }

  sendSupportMessage(data) {
    return http.post(`${this.urlPrefix}/send-support-email`, data);
  }

  sendgridSendMessage(data) {
    return http.post(`${this.urlPrefix}/sendgrid-send-email`, data);
  }

  sendShareReportLink(data) {
    return http.post(`${this.urlPrefix}/send-share-report-link`, data);
  }

  getTemplates() {
    return http.get(`${this.urlPrefix}/templates`);
  }

  createTemplate(data) {
    return http.post(`${this.urlPrefix}/templates`, data);
  }

  deleteTemplate(templateId) {
    return http.delete(`${this.urlPrefix}/templates/${templateId}/delete`);
  }

  deleteTemplateFile(data) {
    return http.post(`${this.urlPrefix}/templates/delete-file`, data);
  }

  getTrigger(data) {
    return http.get(`${this.urlPrefix}/triggers`, { params: data });
  }

  createTrigger(data) {
    return http.post(`${this.urlPrefix}/triggers`, data);
  }

  deleteTrigger(templateId) {
    return http.delete(`${this.urlPrefix}/triggers/${templateId}/delete`);
  }

  // Dynamic form endpoints
  getDynamicForms(data) {
    return http.get(`${this.urlPrefix}/dynamic-form`, { params: data });
  }

  getDynamicFormById(formId) {
    return http.get(`${this.urlPrefix}/dynamic-form/${formId}`);
  }

  deleteDynamicForm(formId) {
    return http.delete(`${this.urlPrefix}/dynamic-form/${formId}`);
  }

  createDynamicForm(data) {
    return http.post(`${this.urlPrefix}/dynamic-form`, data);
  }

  getMessageLogs(data) {
    return http.get(`${this.urlPrefix}/message-logs`, { params: data });
  }

  getMessageLogById(messageLogId) {
    return http.get(`${this.urlPrefix}/message-logs/${messageLogId}`);
  }

  updateMessageLogs(messageLogId, data) {
    return http.put(`${this.urlPrefix}/message-logs/${messageLogId}/update-on-stand-alone`, data);
  }

  getPricingSettingById(settingsId) {
    return http.get(`${this.urlPrefix}/building-setting/sid/${settingsId}`);
  }

  // pricing method for building
  getPricingSettingByBid(buildingId) {
    return http.get(`${this.urlPrefix}/building-setting/${buildingId}`);
  }
  getPricingSettingByBuildingIds(data) {
    return http.post(`${this.urlPrefix}/building-setting/by-buildings`, data);
  }
  createUpdatePricingSettings(data) {
    return http.post(`${this.urlPrefix}/building-setting`, data);
  }

  updatePricingSettingsById(data) {
    return http.post(`${this.urlPrefix}/building-setting/update/sid`, data);
  }

  checkIfUserExists(data) {
    return http.post(`${this.urlPrefix}/check-user`, data);
  }

  getOrgViews(data) {
    return http.post(`${this.urlPrefix}/views`, data);
  }

  getLookerSSOUrl(data) {
    return http.post(`${this.urlPrefix}/looker/generate-sso-url`, data);
  }

  // get campaign users
  getCampaignUsers(audience) {
    return http.get(`${this.urlPrefix}/audience`, { params: { audience } });
  }
  // create campaigns
  createCampaign(data) {
    return http.post(`${this.urlPrefix}/campaigns`, data);
  }
  // update campaigns
  updateCampaign(data, campaignId) {
    return http.post(`${this.urlPrefix}/campaigns/${campaignId}`, data);
  }
  // get campaigns
  getCampaign() {
    return http.get(`${this.urlPrefix}/campaigns`);
  }
  // get notifications
  getNotifications() {
    return http.get(`${this.urlPrefix}/campaign-notifications`);
  }
  // update notifications
  updateNotifications(data, notificationId) {
    return http.post(`${this.urlPrefix}/campaign-notifications/${notificationId}`, data);
  }

  getMyTableAccess() {
    return http.get(`${this.urlPrefix}/getMyTableAccess`);
  }

  getTableauJWT() {
    return http.get(`${this.urlPrefix}/getTableauJWT`);
  }

  getHasTableauAccess() {
    return http.get(`${this.urlPrefix}/check-tableau-access`);
  }

  getTableauWorkbooks() {
    return http.get(`${this.urlPrefix}/get-tableau-workbooks`);
  }

  getTableauWorkbookViews() {
    return http.get(`${this.urlPrefix}/get-tableau-workbook-views`);
  }

  getTableauViewData(views, propertyNames) {
    return http.post(`${this.urlPrefix}/get-tableau-view-data`, { views, propertyNames });
  }

  getReport(reportId) {
    return http.post(`${this.urlPrefix}/get-report`, { reportId });
  }

  createReport(data) {
    return http.post(`${this.urlPrefix}/create-or-update-report`, data);
  }

  generateAccessCode(data) {
    return http.post(`${this.urlPrefix}/generate-access`, data);
  }

  validateOrganisationAndSeats(data) {
    return http.post(`${this.urlPrefix}/check-organisation-seats`, data);
  }

  validateAccessCode(data) {
    return http.post(`${this.urlPrefix}/validate-access`, data);
  }

  createProspectiveUser(data) {
    return http.post(`${this.urlPrefix}/create-selfserve-user`, data);
  }

  getUsageForOrganisation(data) {
    return http.post(`${this.urlPrefix}/get-usage-organisation`, data);
  }

  getUsageForUser(data) {
    return http.post(`${this.urlPrefix}/get-usage-user`, data);
  }

  getExecutiveSummary(dashboardId, dashboardTabIndex) {
    return http.get(
      `${this.urlPrefix}/executive-summary/dashboardId/${dashboardId}/dashboardTabIndex/${dashboardTabIndex}`
    );
  }

  getUsageByUserId(data) {
    return http.post(`${this.urlPrefix}/get-usage-by-user-id`, data);
  }

  createNewBuildingSettingByBid(data) {
    return http.post(`${this.urlPrefix}/new-building-setting`, data);
  }

  deleteCompSetBySettingId(id) {
    return http.delete(`${this.urlPrefix}/building-setting/${id}/delete`);
  }

  updateColumnValuesForAllCompSets(buildingId, organisationId, updatedData) {
    return http.post(`${this.urlPrefix}/building-setting/update`, { buildingId, organisationId, updatedData });
  }

  saveAddressMethod(setting) {
    return http.post(`${this.urlPrefix}/building-setting/save-address`, setting);
  }

  setCurrentCompSetDefault(id) {
    http.post(`${this.urlPrefix}/building-setting/set-default`, { id });
  }

  usageTracking(data) {
    return http.post(`${this.urlPrefix}/usage-tracking`, data);
  }
}

export const UserApi = new UserApiService();
