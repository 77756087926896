import * as React from 'react';
import { cn } from '@/lib/utils';
import { Textarea } from '@/components/ui/textarea';

export type CommentInputProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  onSubmit?: () => void;
};

const CommentInput = React.forwardRef<HTMLTextAreaElement, CommentInputProps>(
  ({ className, onSubmit, ...props }, ref) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        if (!e.shiftKey) {
          e.preventDefault();
          onSubmit?.();
        }
      }
    };

    return (
      <Textarea
        className={cn(
          'flex h-full bg-transparent border-none ring-none outline-none px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 min-h-14 max-h-14 resize-none',
          className
        )}
        ref={ref}
        onKeyDown={handleKeyDown}
        {...props}
      />
    );
  }
);

CommentInput.displayName = 'Input';

export default CommentInput;
