import moment from 'moment';
import React from 'react';
import { MultiSelectOption } from 'src/types/Forms/Forms';

interface ReportBuilderReportSummaryProps {
  reportProperties: MultiSelectOption[];
  reportDate: string | Date | undefined;
}

const ReportBuilderReportSummary: React.FC<ReportBuilderReportSummaryProps> = ({ reportProperties, reportDate }) => {
  return (
    <div className={`bg-gray-25 border-none rounded-sm p-5`}>
      <span className="text-gray-400 grid grid-cols-[150px_1fr] mb-2">
        Properties:
        <span className="text-gray-800">
          {reportProperties?.reduce((acc, curr) => `${acc}${acc ? ',' : ''} ${curr.label}`, '')}
        </span>
      </span>
      <span className="text-gray-400 grid grid-cols-[150px_1fr]">
        Report Dated as of :<span className="text-gray-800">{moment(reportDate).format('MMMM Do, YYYY')}</span>
      </span>
    </div>
  );
};

export default ReportBuilderReportSummary;
