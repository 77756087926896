import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { LoadingSpinner } from '@/components/ui/spinner';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface ReportBuilderEmailFormProps {
  emailSubmitted: boolean;
  setEmailSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportBuilderEmailForm: React.FC<ReportBuilderEmailFormProps> = ({ setEmailSubmitted }) => {
  const [loadingEmailSubmission, setLoadingEmailSubmission] = useState(false);
  const emailSubmissionFormSchema = z.object({
    email: z.string().email({
      message: 'Please enter a valid email address',
    }),
  });

  const emailSubmissionForm = useForm<z.infer<typeof emailSubmissionFormSchema>>({
    resolver: zodResolver(emailSubmissionFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    setError,
  } = emailSubmissionForm;

  const onSubmit = async (values: z.infer<typeof emailSubmissionFormSchema>) => {
    setLoadingEmailSubmission(true);

    try {
      const { email } = values;
      console.log(email);
      setEmailSubmitted(true);
    } catch (error) {
      setError('email', {
        message: 'Please provide a valid email address',
      });
    } finally {
      setLoadingEmailSubmission(false);
    }
  };

  return (
    <div className="h-full grid place-items-center bg-gray-800">
      <div className="bg-white-50 flex flex-col gap-6 items-center pt-5 mx-auto h-[300px] w-[500px] rounded-lg">
        <p className="font-bold text-lg text-gray-600">Leni requests your action to continue</p>
        <Form {...emailSubmissionForm}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 w-[80%]">
            <FormField
              control={emailSubmissionForm.control}
              name="email"
              render={({ field }) => (
                <FormItem className="text-left">
                  <FormLabel className="text-xs text-gray-500">Email</FormLabel>
                  <FormControl
                    className={`focus:outline-none active:outline-none ${errors?.email && 'border-error-500'}`}>
                    <Input
                      icon={<Mail className="h-[16px] w-[16px] text-gray-400" />}
                      placeholder="john.doe@gmail.com"
                      {...field}
                      error={errors?.email}
                      aria-invalid={errors?.email ? 'true' : 'false'}
                      className="focus:outline-none active:outline-none"
                      type="email"
                    />
                  </FormControl>
                  {errors?.email && (
                    <FormDescription className="text-error-500 text-xs">*{errors?.email.message}</FormDescription>
                  )}
                </FormItem>
              )}
            />
            <Button className="text-black bg-accent-400 cursor-pointer hover:bg-accent-200 mt-5 w-full" size="sm">
              {!loadingEmailSubmission ? (
                'Continue'
              ) : (
                <div className="flex items-center justify-center">
                  <LoadingSpinner className="mr-3" height={12} width={12} />
                  <span className="flex items-center text-center">Submitting Email...</span>
                </div>
              )}
            </Button>
          </form>
        </Form>
        <span className="text-[8px] mt-auto pb-5">
          * By providing your email you acknowledge that Leni may contact you with information regarding their product
          offerings
        </span>
      </div>
    </div>
  );
};

export default ReportBuilderEmailForm;
