import React from 'react';
import Image from 'next/image';
import moment from 'moment';

interface ReportBuilderReportHeaderProps {
  reportTitle: string;
  reportDate: string | Date | undefined;
}

const ReportBuilderReportHeader: React.FC<ReportBuilderReportHeaderProps> = ({ reportTitle, reportDate }) => {
  return (
    <div className="grid grid-cols-3 place-items-center">
      <span className="text-xl justify-self-start">{reportTitle}</span>
      <Image src="/static/images/leni-logo-black.svg" height={80} width={80} alt="Leni logo" />
      <span className="text-xl justify-self-end">{moment(reportDate).format('MMMM Do, YYYY')}</span>
    </div>
  );
};

export default ReportBuilderReportHeader;
