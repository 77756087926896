import http from '../http';
import axios from 'axios';
/**
 * Property Api Service
 */
export class GptService {
  urlPrefix =
    process.env.NODE_ENV === 'production'
      ? 'kxr4gtlb6iddnleemm6pfaw4su0gweba.lambda-url.ca-central-1.on.aws'
      : 'https://wx3bzidmrd5cutd3gopivxey2u0dskia.lambda-url.ca-central-1.on.aws/ ';

  reAsk(data) {
    return axios.post(`https://${this.urlPrefix}/ask-again`, data);
  }
  getHistory(user) {
    return axios.post(`https://${this.urlPrefix}/get-chat-history`, { user });
  }

  giveFeedback(data) {
    return axios.post(`https://aagp5sztmkkksgljnx3iu36j5a0lqyqd.lambda-url.ca-central-1.on.aws/feedback`, data);
  }

  runModels(question, user, screenCapture, urlToSummarize, shortSummary, questionHistory) {
    return axios.post(`https://aagp5sztmkkksgljnx3iu36j5a0lqyqd.lambda-url.ca-central-1.on.aws/run-models`, {
      question,
      user,
      screenCapture,
      urlToSummarize,
      shortSummary,
      usePredefinedFormat: true,
      questionHistory,
    });
  }

  runModelsStreaming(formData) {
    return fetch(`http://rqo0jgx96h.execute-api.ca-central-1.amazonaws.com/stage/run-models-streaming`, {
      method: 'POST',
      body: formData,
    });
  }

  runModelsWithFileContext(formData) {
    return axios.post(`https://rqo0jgx96h.execute-api.ca-central-1.amazonaws.com/stage`, formData);
  }
}

export const GptApi = new GptService();
