import ReportBuilderEmailForm from 'components/Forms/ReportBuilderEmailForm';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import ReportBuilderShareReportContainer from './ReportBuilderShareReport';

const ReportBuilderShareContainer = () => {
  const router = useRouter();
  const reportId = router.asPath.split('/').filter(Boolean).pop();
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  if (!emailSubmitted) {
    return <ReportBuilderEmailForm emailSubmitted={emailSubmitted} setEmailSubmitted={setEmailSubmitted} />;
  }
  return <ReportBuilderShareReportContainer reportId={reportId as string} />;
};

export default ReportBuilderShareContainer;
