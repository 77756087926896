import { TABLEAU_DASHBOARDS } from 'data/tableauDashboards';
import { User } from 'src/types/User/user';

export const getTableauDashboardCategories = (user: User | null) => {
  // No user, no dashboards
  if (!user) return [];
  // A special case is no longer needed for the dev user as all views are in one file and can be controlled with dashboardViews
  // Filter for the dashboard categories that the user has views for subdashboards within
  return TABLEAU_DASHBOARDS.filter(dashboard =>
    dashboard.dashboardTabs.some(tab => user.dashboardViews?.includes(tab.viewName))
  );
};

export const isTableauDashboard = (item: object) => {
  return 'dashboardTabs' in item;
};
