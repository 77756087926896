import React from 'react';
import { useUser } from 'contexts/UserContext.tsx';
import { getSourceUrl } from 'components/Avatar/generator';

interface LeniQQuestionProps {
  question: string;
}

const LeniQQuestionComponent: React.FC<LeniQQuestionProps> = ({ question }) => {
  const { user: currentUser } = useUser();

  const avatarSrc = getSourceUrl(currentUser?.avatar);
  return (
    <div className="flex flex-row w-full items-start  justify-end">
      <div className="flex flex-col items-start my-4 py-2 text-sm">
        <div className="flex  p-2 px-4 flex-col items-end gap-2 rounded-xl bg-slate-100  border-primary-200  transition-all text-start ml-9 w-fit">
          {question}
        </div>
      </div>
      <div
        className=" flex items-start rounded-full ml-2 border-gray-300 border w-8 h-8  items-center justify-center my-4 mt-5 py-2   bg-blue-50  relative overflow-hidden "
        style={{ flex: '0 0 32px' }}>
        <img
          src={avatarSrc}
          width={20}
          height={20}
          className="absolute top-0 left-0 h-8 w-8 object-cover"
          alt="Sage Plus Icon"
        />
      </div>
    </div>
  );
};

export default LeniQQuestionComponent;
