import React, { useEffect, useState } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { MultiSelectGridOptionWithImage } from 'src/types/Forms/Forms';
import ReportBuilderResizableItem from '../ReportBuilderResizableItem';
import { calculateLayout } from 'utils/ReportBuilder/reportBuilderHelpers';
import useReportBuilderContext from 'utils/hooks/useReportBuilderContext';

interface ReportBuilderReportBodyProps {
  reportKPIs: MultiSelectGridOptionWithImage[];
  parentRef: React.RefObject<HTMLElement>;
  headerRef: React.RefObject<HTMLElement>;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

const ReportBuilderReportBody: React.FC<ReportBuilderReportBodyProps> = ({ reportKPIs, parentRef, headerRef }) => {
  const {
    state: { isViewingReport },
  } = useReportBuilderContext();
  const [layout, setLayout] = useState<Layout[]>(calculateLayout(reportKPIs));
  const [gridHeight, setGridHeight] = useState(0);
  const onLayoutChange = (newLayout: Layout[]) => {
    setLayout(newLayout); // This will update the layout state but not reflect changes in order
  };

  useEffect(() => {
    if (!reportKPIs) return;
    const newLayout = calculateLayout(reportKPIs);
    setLayout(newLayout);
  }, [reportKPIs]);

  useEffect(() => {
    const calculateGridHeight = () => {
      if (headerRef.current && parentRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        const parentHeight = parentRef.current.offsetHeight;
        setGridHeight(parentHeight - headerHeight - 50); // Adjust for spacing if needed
      }
    };

    // Use ResizeObserver to watch for changes in the parent container size
    const resizeObserver = new ResizeObserver(calculateGridHeight);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, [parentRef, isViewingReport]);

  return (
    <div className="col-span-2 p-0 relative">
      <ResponsiveGridLayout
        className="layout"
        margin={[10, 50]}
        layouts={{ lg: layout }}
        cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
        rowHeight={250}
        onLayoutChange={onLayoutChange}
        isResizable={false}
        isDraggable={false}
        style={{
          maxHeight: `${gridHeight}px`,
        }}>
        {reportKPIs.map((kpi, index) => (
          <div key={`${kpi.id}-resizable-kpi`} data-grid={layout[index]}>
            <ReportBuilderResizableItem kpi={kpi} borderStyle="border-none" />
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
};

export default ReportBuilderReportBody;
