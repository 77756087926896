import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import React from 'react';

interface ReportBuilderSingleRowTableChartProps {
  id: string;
  data: Array<Record<string, string | number>>;
}

const ReportBuilderSingleRowTableChart: React.FC<ReportBuilderSingleRowTableChartProps> = ({ id, data }) => {
  // Guard clause: if no data is provided
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // Extract column names from the first object
  const columnNames = data.map(item => item['Measure Names']);
  return (
    <div id={id} className="w-full h-full">
      <Table>
        <TableHeader>
          <TableRow className="border-b-[1px] border-gray-400">
            {columnNames.map(column => (
              <TableHead key={column} className="border-r-[1px] last:border-r-0 border-gray-400 text-center">
                {column}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            {data.map((column, index) => (
              <TableCell
                key={`single-row-table-cart-${index}`}
                className="border-r-[1px] last:border-r-0 border-gray-400 text-center">
                {column['Measure Values']}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default ReportBuilderSingleRowTableChart;
