import { ReportBuilderContext } from 'contexts/ReportBuilderContext';
import { useContext } from 'react';

const useReportBuilderContext = () => {
  const context = useContext(ReportBuilderContext);
  if (!context) {
    throw new Error('useReportBuilderContext must be used within a DashboardContextProvider');
  }
  return context;
};

export default useReportBuilderContext;
