import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import React from 'react';

interface ReportBuilderTableChartProps {
  id: string;
  data: Array<Record<string, string | number>>;
}

const ReportBuilderTableChart: React.FC<ReportBuilderTableChartProps> = ({ id, data }) => {
  // Guard clause: if no data is provided
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // Extract column names from the first object
  const columnNames = Object.keys(data[0]);
  const reorderedColumns = ['Property Name', ...columnNames.filter(col => col !== 'Property Name' && col !== '#')];

  const shouldApplyColoring = (column: string) => column === 'AVG $ Gain / Loss' || column === 'AVG % Gain / Loss';

  const getCellTextColor = (value: string | number | null) => {
    if (typeof value === 'string' && value.includes('-')) {
      return 'text-red-500'; // Red for negative values
    }
    return 'text-green-500'; // Green for positive values
  };
  return (
    <div id={id} className="w-full h-full">
      <Table>
        <TableHeader>
          <TableRow className="border-b-[1px] border-gray-400">
            {reorderedColumns.map(column => (
              <TableHead key={column} className="border-r-[1px] last:border-r-0 border-gray-400 text-center">
                {column}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex} className="border-b-[1px] border-gray-400">
              {reorderedColumns.map(column => (
                <TableCell
                  key={column}
                  className={`border-r-[1px] last:border-r-0 border-gray-400 text-center ${
                    shouldApplyColoring(column) ? getCellTextColor(row[column]) : ''
                  }`}>
                  {typeof row[column] === 'number' || row[column] === null
                    ? (row[column] ?? '-')
                    : row[column]?.toString()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ReportBuilderTableChart;
