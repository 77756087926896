import { ReportBuilderKPIMap } from 'data/reportBuilderData';
import { MultiSelectGridOptionWithImage } from 'src/types/Forms/Forms';

export const calculateLayout = (reportKPIs: MultiSelectGridOptionWithImage[]) =>
  reportKPIs.map((kpi, index) => {
    const isBig =
      kpi.id === ReportBuilderKPIMap.AGING_SUMMARY ||
      kpi.id === ReportBuilderKPIMap.TENANTS_WITH_A_BALANCE ||
      kpi.id === ReportBuilderKPIMap.CURRENT_TENANT_UNIT_STATUS ||
      kpi.id === ReportBuilderKPIMap.OCCUPANCY_BY_PROPERTY ||
      kpi.id === ReportBuilderKPIMap.TOP_10_TRADE_OUT_GAINS ||
      kpi.id === ReportBuilderKPIMap.BOTTOM_10_TRADE_OUT_GAINS ||
      kpi.id === ReportBuilderKPIMap.T6_LEASE_RENEWAL_RATES_BY_PROPERTY;
    const isWide = kpi.id === ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL;
    return {
      i: `${kpi.id}-resizable-kpi`,
      x: kpi.x ? kpi.x : (index % 2) * 1, // Alternates between 0 and 1 for two columns
      y: kpi.y ? kpi.y : Math.floor(index / 2), // Increments every two items to keep two items per row
      w: kpi.w ? kpi.w : isWide || isBig ? 2 : 1, // Set column width
      h: kpi.h ? kpi.h : isBig ? 3 : isWide ? 1 : 2, // Set row height
      minW: 1,
      maxW: 2,
      minH: 1,
      maxH: 4,
    };
  });
