import { TABLEAU_DASHBOARDS } from 'data/tableauDashboards';

export const getTableauUrl = (currentDashboard: string, dashboardTab: number) => {
  if (!currentDashboard) return process.env.NEXT_PUBLIC_TABLEAU_EXEC_SUMMARY_URL;
  const dashboard = TABLEAU_DASHBOARDS.find(dashboard => dashboard.pageId === currentDashboard);

  // If no dashboard selected, return the exec summary dashboard
  if (!dashboard) return process.env.NEXT_PUBLIC_TABLEAU_EXEC_SUMMARY_URL;

  // If no dashboardTab selected, return the 0th indexed tab
  // Otherwise, return the indexed sub-dashboard tab
  return dashboard.dashboardTabs[dashboardTab]?.url || process.env.NEXT_PUBLIC_TABLEAU_EXEC_SUMMARY_URL;
};
