import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { getSourceUrl, generateRandomAvatar } from './generator';
import { useUser } from 'contexts/UserContext.tsx';
import { User } from 'lucide-react';

function AvatarComponent({ name, shape = 'circle', size = 'sm', mt, avatar, ...props }) {
  const { user: currentUser } = useUser();

  const getAltName = name => {
    return name
      .split(' ')
      .map(n => n[0])
      .splice(0, 2)
      .join('')
      .toUpperCase();
  };

  const isCircular = shape === 'circle';

  const avatarSrc =
    avatar && Object.keys(avatar).length > 0
      ? getSourceUrl(avatar)
      : currentUser?.avatar && Object.keys(currentUser.avatar).length > 0 && name === currentUser.displayName
        ? getSourceUrl(currentUser.avatar)
        : generateRandomAvatar(shape, name);

  const sizeClasses = {
    xs: 'w-6 h-6',
    sm: 'w-10 h-10',
    md: 'w-12 h-12',
    lg: 'w-16 h-16',
    profile: 'w-55 h-55 min-h-[90px] md:min-h-[100px] xl:min-h-[150px] min-w-[90px] md:min-w-[100px] xl:min-w-[150px]',
  };

  return (
    <Avatar
      {...props}
      className={`bg-gray-100 ${isCircular ? 'rounded-full' : 'rounded-md'} ${sizeClasses[size]}`}
      style={{ marginTop: mt ? mt : '-5px' }}>
      <AvatarImage src={avatarSrc} alt={name} className={isCircular ? 'rounded-full' : 'rounded-md'} />
      <AvatarFallback className="flex items-center justify-center w-full h-full text-gray-500">
        {name ? getAltName(name) : <User size={size === 'xs' ? 10 : size === 'sm' ? 16 : size === 'md' ? 20 : 88} />}
      </AvatarFallback>
    </Avatar>
  );
}

AvatarComponent.propTypes = {
  shape: PropTypes.oneOf(['circle', 'square']),
  name: PropTypes.string,
  mt: PropTypes.string,
  avatar: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'profile']),
};

export default AvatarComponent;
